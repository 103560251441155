.Home {
    text-align: initial;
    background-image: white;
    min-height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .Home-header-container {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 0px 20px;
  }
  
  .Home-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .Home-header {
    font-size: calc(10px + 2vmin);
    color: white;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .Home-text {
    color: white;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    font-style: italic;

  }

  .Home-header-logo {
    height: 10vmin;
    pointer-events: none;
  }

  .Home-link {
    cursor: pointer;
    position: relative;
    text-decoration: none !important;
    background-color: #27B589;
    margin: 20px 10px 0px 0px;
    border-radius: 25px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1)
  }

  .notification-badge {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

  .Home-link-container {
    padding: 0px 10px 0px 20px;
  }

  .Home-link-title {
    color: white;
    padding: 15px;
    text-align: center;
  }

  .Home-logo {
    align-self: flex-start;
    transform: scaleX(-1);
    min-height: 40vh;
  }
  
  .Home-logo-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .Home-whatsapp-link {
    color: #D9E8BB;
    background-color: #A4B28A;
    margin: 10px 0px 0px 0px;
    padding: 10px;
    border-radius: 13px;
    max-width: 290px;
  }

  .Home-circle-link {
    color: darkslategrey;
    background-color: #B0DECF;
    border-radius: 10px;
    align-self: flex-end;
    display: flex;
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 600;
    padding: 11px;
    margin-bottom: 10px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  }

  .Home-icon {
    margin-top: 10px;
    font-size: 20px;
    color: #6F7874;
  }

  .Home-drawer-link {
    color: #a9a9a9;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .Home-drawer-link-text {
    color: gray;
    margin: 10px;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 600;
  }