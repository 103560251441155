body,
html {
  height: 100%;
  margin: 0;
}

p {
  color: #c6dca7;
}

b {
  color: #FFFF;
}

.Wallet-header-container {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.Wallet-logo-header {
  margin-top: 1.25rem;
  height: 10vmin;
}

.Wallet-right-text {
  font-size: 2.5rem;
  margin-top: 17rem;
  margin-left: 8rem;

  .member-text {
    margin-top: -1.5rem;
    margin-bottom: 4rem;
    font-size: 1.5rem;
  }


  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
    margin-top: 10rem;
    margin-left: 5rem;

    .member-text {
      color: white;
      margin-top: -1.5rem;
      margin-bottom: -1.5rem;
      font-size: 1rem;
    }
  }
}

.Wallet-divider {
  display: flex;
  height: 80vh;

  @media only screen and (max-width: 600px) {
    transform: rotate(90deg);
    flex-direction: row;
    height: 50vh;
  }
}

.Wallet-left {
  flex: 0.6;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bccccc;

  .Image-wallet {
    height: 55vmin;
  }

  @media only screen and (max-width: 600px) {
    max-height: none;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    .Image-wallet {
      height: 55vmin;
    }
  }
}

.Image-wallet img {
  max-width: 80%;
  max-height: 80vh;
  height: 200vh;
  object-fit: contain;
}

.Wallet-right {
  flex: 1.1;
  background-color: #144943;
}

.Wallet-logo {
  margin: 3rem 0rem 0rem 50rem;
  height: 13vmin;

  @media only screen and (max-width: 600px) {
    margin: 0rem 0rem 10rem 21.5rem;
    height: 13vmin;
  }
}

.status-active-defaulter {
  color: black;
  background-color: yellow;
  border-radius: 1rem;
  padding: 5px 5px 5px 5px;
  opacity: 50%;
}

.upgrade-button {
  color: white;
  background-color: gray; 
  border-radius: 0.5rem;
  padding: 5px 5px 5px 5px;
  font-size: 1.5rem;

  @media only screen and (max-width: 600px) {
  font-size: 1rem;
  margin-bottom: 2rem;
  }
}

.wallet-unavailable {
  color: white;
  padding-top: 2rem;
  margin-bottom: 7rem;
}