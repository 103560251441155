.Health-cicle-link {
    border-radius: 8px;
    text-decoration: none !important;
}

.Health-cicle-header {
  margin: 15px 0px 10px 0px;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.Health-cicle-text {
    color: #6F7874;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0px 0px 10px;
}

.Health-cicle-header-logo {
  height: 10vmin; 
  pointer-events: none;
}

.Health-cicle-title-text {
  text-align: center;
  font-size: 14px;
}

.Health-cicle-title-logo {
  align-self: center;
  pointer-events: none;
}

.Health-cicle-chart-logo {
  align-self: center;
  pointer-events: none;
  max-width: 70%;
}

.Health-cicles {
    text-align: initial;
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px 10px 0px 10px;
    justify-content: flex-start;
  }

  .Health-cicle-tab-content {
    display: flex;
    flex: 1;
    background-color: 'white';
  }

  .Health-cicle-title-text {
    color: gray;
    margin: 10px;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 600;
  }

  .Health-cicle-container {
    display: flex;
    color: gray;
    align-items: center;
    flex: 1;
    justify-content: space-between;
  }

  .Health-cicle-name {
    color: #10DB9C;
    font-size: 16px;
    line-height: 50px;
    vertical-align: center !important;
  }

  .Health-cicle-name-gray {
    color: #A0A0A0;
    font-size: 16px;
    line-height: 50px;
    vertical-align: center !important;
  }

  .Health-cicle-name::marker {
    font-size: 50px;
  }

  .Health-cicle-name-gray::marker {
    font-size: 50px;
  }

  .Health-cicle-detail {
    font-size: 13px;
    font-weight: 600;
  }

  .Health-cicle-detail-container {
    margin-top: 25px;
    list-style-type:disc;
    width: 80%;
  }

  .Health-cicle-details-icon {
    padding-right: 10px;
    color: gray;
  }

  .Health-cicle-details-ul {
    margin-top: 0;
    color: gray;
  }