.App {
    text-align: center;
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-header {
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #a9a9a9;
    text-decoration: none !important;
  } 
  
  .App-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
  }
  
  .App-form-input {
    background-color: #e8e8e8;
    margin: 10px 20px;
    border-width: 1px;
    font-size: 18px;
    border-radius: 10px;
    padding: 14px;
    border-style: solid;
  }
  
  .App-form-submit {
    font-size: 22px;
    background-color: #03a366;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    border-width: 0;
    padding: 14px;
    margin: 20px;
  }
  
  .App-error-container {
    background-color: red;
    width: 90%;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    position: absolute;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  