.Reminders {
    text-align: initial;
    background-image: linear-gradient(to bottom, #B0DECF , #F0FFFF);
    min-height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px 10px 0px 20px;
    justify-content: space-between;
  }

  .Reminders-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .Reminders-header {
    font-size: calc(10px + 2vmin);
    color: white;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
  }

  .Reminders-text {
    color: black;
    font-family: inherit;
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .Reminders-icon {
    margin: 10px;
    font-size: 20px;
    align-self: end;
    color: gray;
  }
  
  .Reminders-drawer-link {
    color: #a9a9a9;
    margin: 10px;
  }

  .Reminders-link {
    color: black;
    font-size: 20px;
  }

  .Reminders-item-link {
    color: black;
    font-size: 17px;
  }

  .Reminders-list {
    margin: 10px;
  }

  .Reminders-list-inline {
    list-style-type:circle;
    margin: 10px;
  }