.medicine-check-confirmation{
  margin-right: 40px;
  margin-left: 40px;


  .check-confirmation-title{
    /* font-size: 40px; */
    padding-top: 150px;
    text-align: center;
  }

  h2{
    text-align: center;
  }

  .image-check-container{
    text-align: center;
    margin-bottom: -8rem;

    .image-check{
      max-width:250px;
      max-height:200px;
      width: auto;
      height: auto;
    }
  }
  
  .image-soul-logo{
    max-height:50px;
    max-width:100px;
    bottom: 0;
    margin-bottom: 20px;
    margin-left: 20%;
    position: fixed;
  }
}