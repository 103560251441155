.Reminder-medicine-link {
    border-radius: 8px;
}

.Reminder-medicine-header {
    margin: 15px 0px 10px 0px;
    border-radius: 8px;
    flex-direction: column;
    background-color: white;
    min-height: 600px;
    position: relative;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1)
}

.Medicine-reminder-text {
    color: #6F7874;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0px 0px 10px;
}

.Agenda-reminders {
    text-align: initial;
    background-image: linear-gradient(to bottom, #B0DECF , #F0FFFF);
    min-height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px 10px 0px 10px;
    justify-content: flex-start;
  }

  .Medicine-reminder-tab-content {
    display: flex;
    flex: 1;
    background-color: 'white';
  }

  .Medicine-reminder-title-text {
    color: gray;
    margin: 10px;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 600;
  }

  .Medicine-container {
    display: flex;
    color: gray;
    align-items: center;
    flex: 1;
    justify-content: space-between;
  }

  .Medicine-name {
    margin-right: 10px;
    color: 'gray';
    margin-left: 20px;
  }

  .Medicine-detail {
    font-size: 13px;
    font-weight: 600;
  }

  .Medicine-detail-container {
    border-width: 1px;
    border-style: solid;
    border-color: lightgray;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .Medicine-details-icon {
    padding-right: 10px;
    color: gray;
  }

  .Agenda-details-ul {
    margin-top: 0;
    color: gray;
  }