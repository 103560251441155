.Reminder-medicine-link {
    border-radius: 8px;
}

.Reminder-medicine-header {
    margin: 15px 0px 10px 0px;
    border-radius: 8px;
    flex-direction: column;
    background-color: white;
    min-height: 600px;
    position: relative;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1)
}

.Medicine-reminder-text {
    color: #6F7874;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0px 0px 10px;
}

.Medicine-title-text {
  color: #6F7874;
  align-self: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0px 0px 10px;
}

.Medicine-reminders {
    text-align: initial;
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px 10px 0px 10px;
    justify-content: flex-start;
  }

  .Medicine-reminder-tab-content {
    display: flex;
    flex: 1;
    background-color: 'white';
  }

  .Medicine-reminder-title-text {
    color: gray;
    margin: 10px;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 600;
  }

  .Medicine-container {
    display: flex;
    color: gray;
    flex: 1;
    flex-direction: column;
  }

  .Medicine-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .Medicine-dose-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: 20px;
  }

  .Medicine-dose-container {
    align-self: flex-start;
    margin-bottom: 10px;
  }

  .Medicine-dose-name {
    font-size: 14px;
    margin: 0;
    padding-left: 5px;
  }

  .Medicine-dose-name-checked {
    font-size: 14px;
    text-decoration: line-through;
    margin: 0;
    padding-left: 5px;
  }

  .Medicine-name {
    margin-right: 10px;
    color: white;
    margin-left: 20px;
  }

  .Medicine-detail {
    font-size: 13px;
    font-weight: 600;
  }

  .Medicine-alert-icon {
    margin-left: 5px;
    color: red;
  }

  .Medicine-detail-container {
    border-width: 1px;
    border-style: solid;
    border-color: lightgray;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .Medicine-details-icon {
    padding-right: 10px;
    color: gray;
  }

  .Medicine-details-ul {
    margin-top: 10px;
    color: gray;
  }

  .highlight {
    color: red !important;
 }

 .Medicine-details-icon-plus {
  padding-right: 10px;
  color: white;
 }

 .Medicine-add-detail-container {
    border-width: 0px;
    color: white;
    font-weight: 600;
    background-color: #10DB9C;
    border-radius: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
 }
  
 .modal-container {
  background-color: white;
  padding: 10px;
  text-align: center;
  align-self: center;
  border-color: #6F7874;
  margin-top: 100px;
  margin: 100px 20px 20px 20px;
 }

 .modal-container-success {
  background-color: green;
  padding: 10px;
  text-align: center;
  align-self: center;
  border-color: #6F7874;
  margin-top: 100px;
  margin: 100px 20px 20px 20px;
 }

 .modal-container-error {
  background-color: red;
  padding: 10px;
  text-align: center;
  align-self: center;
  border-color: #6F7874;
  margin-top: 100px;
  margin: 100px 20px 20px 20px;
 }

 .spinner-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51,51,51,0.7);
  z-index: 2500;
}
.loading-spinner {
  position: fixed;
  left: 45%;
  top: 45%;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}