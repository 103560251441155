.App-login {
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Login-logo {
  height: 15vmin;
  pointer-events: none;
}

.App-header {
  font-size: calc(10px + 2vmin);
  color: white;
}

.Login-link {
  color: #827E7E;
  text-decoration: none !important;
} 

.App-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
}

.App-form-container {
  background-color: #D9D9D9;
  border-radius: 25px;
  padding: 20px;
  margin-top: 20%;
}

.Login-form-input {
  background-color: #FFF;
  margin: 10px 20px;
  border-width: 0px;
  font-size: 18px;
  border-radius: 25px;
  padding: 14px;
  border-style: solid;
}

.Login-form-input::placeholder{
  color: #A0A0A0;
}

.Login-form-submit {
  font-size: 22px;
  background-color: #03a366;
  color: #fff;
  border-radius: 25px;
  border-width: 0;
  padding: 14px;
  margin: 20px;
}

.App-error-container {
  background-color: red;
  width: 90%;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  position: absolute;
}

.Login-subscribe-link {
  display: block;
  text-decoration: none !important;
  background-color: #19D792;
  color: #fff;
  border-radius: 25px;
  border-width: 0;
  padding: 14px;
  margin-top: 20px;
  text-decoration: none;
  align-self: flex-end;
}

.App-assert-container {
  background-color: green;
  width: 90%;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-weight: 700;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
