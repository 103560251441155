* {
    font-family: Roboto, sans-serif;  
  }
  
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Regular.ttf');
      font-weight: normal;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Italic.ttf');
      font-weight: normal;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Bold.ttf');
      font-weight: bold;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-BoldItalic.ttf');
      font-weight: bold;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Thin.ttf');
      font-weight: 200;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-ThinItalic.ttf');
      font-weight: 200;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Light.ttf');
      font-weight: 100;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-LightItalic.ttf');
      font-weight: 100;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Medium.ttf');
      font-weight: 300;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url('Roboto-MediumItalic.ttf');
      font-weight: 300;
      font-style: italic;
  }