.calendar-container {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 10px 10px 10px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.navigation-buttons button {
  background-color: #27B589;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.navigation-buttons button:hover {
  background-color: #1b9770;
}
.days-container {
  display: flex;
  flex-direction: column;
}
.day {
  background-color: #F9F9F9;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.date {
  font-weight: bold;
  color: #333;
}
.dose-check {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  padding: 5px;
  margin-top: 5px;
  color: #555;
}

.dose-check-button{
  color: #27B589 !important;
}

.dose-not-check {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  padding: 5px;
  margin-top: 5px;
  color: #555;
}


.dose-not-check-button{
  color: #c70808 !important;
}

.dose-wait {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  padding: 5px;
  margin-top: 5px;
  color: #555;
}

.dose-wait-button{
  color: #007BFF !important;
}