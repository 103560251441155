.friend {
    background-color: green;
    background: green !important;
    color: white;
}


.friend-screen{
  padding-top: 10px;
}

h2{
 text-align: center;
}