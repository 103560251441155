body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    font-family: Roboto, sans-serif;  
  }
  
  @font-face {
      font-family: 'Roboto';
      src: url(/static/media/Roboto-Regular.8a36205b.ttf);
      font-weight: normal;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url(/static/media/Roboto-Italic.cebd892d.ttf);
      font-weight: normal;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url(/static/media/Roboto-Bold.b8e42971.ttf);
      font-weight: bold;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url(/static/media/Roboto-BoldItalic.fd6e9700.ttf);
      font-weight: bold;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url(/static/media/Roboto-Thin.66209ae0.ttf);
      font-weight: 200;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url(/static/media/Roboto-ThinItalic.7bcadd06.ttf);
      font-weight: 200;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url(/static/media/Roboto-Light.881e150a.ttf);
      font-weight: 100;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url(/static/media/Roboto-LightItalic.5788d5ce.ttf);
      font-weight: 100;
      font-style: italic;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url(/static/media/Roboto-Medium.68ea4734.ttf);
      font-weight: 300;
      font-style: normal;
  }
   
  @font-face {
      font-family: 'Roboto';
      src: url(/static/media/Roboto-MediumItalic.c16d19c2.ttf);
      font-weight: 300;
      font-style: italic;
  }
.App-login {
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Login-logo {
  height: 15vmin;
  pointer-events: none;
}

.App-header {
  font-size: calc(10px + 2vmin);
  color: white;
}

.Login-link {
  color: #827E7E;
  text-decoration: none !important;
} 

.App-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
}

.App-form-container {
  background-color: #D9D9D9;
  border-radius: 25px;
  padding: 20px;
  margin-top: 20%;
}

.Login-form-input {
  background-color: #FFF;
  margin: 10px 20px;
  border-width: 0px;
  font-size: 18px;
  border-radius: 25px;
  padding: 14px;
  border-style: solid;
}

.Login-form-input::-webkit-input-placeholder{
  color: #A0A0A0;
}

.Login-form-input::placeholder{
  color: #A0A0A0;
}

.Login-form-submit {
  font-size: 22px;
  background-color: #03a366;
  color: #fff;
  border-radius: 25px;
  border-width: 0;
  padding: 14px;
  margin: 20px;
}

.App-error-container {
  background-color: red;
  width: 90%;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  position: absolute;
}

.Login-subscribe-link {
  display: block;
  text-decoration: none !important;
  background-color: #19D792;
  color: #fff;
  border-radius: 25px;
  border-width: 0;
  padding: 14px;
  margin-top: 20px;
  text-decoration: none;
  align-self: flex-end;
}

.App-assert-container {
  background-color: green;
  width: 90%;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-weight: 700;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.App {
    text-align: center;
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-header {
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #a9a9a9;
    text-decoration: none !important;
  }
  
  .Subscribe-form {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .Subscribe-form-title {
    align-self: flex-start;
    margin-left: 20px;
  }
  
  .Subscribe-form-input {
    background-color: #e8e8e8;
    margin: 10px 20px;
    border-width: 0px;
    font-size: 18px;
    border-radius: 15px;
    padding: 14px;
    border-style: solid;
  }
  
  .Subscribe-form-submit {
    font-size: 22px;
    background-color: #19D792;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    border-width: 0;
    padding: 14px;
    margin: 20px;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  .App-error-container {
    background-color: red;
    width: 90%;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    position: absolute;
  }

  .Subscribe-header-container {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
  }

  .Subscribe-logo {
    height: 10vmin;
    pointer-events: none;
  }
  
.Home {
    text-align: initial;
    background-image: white;
    min-height: 100vh;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .Home-header-container {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 0px 20px;
  }
  
  .Home-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .Home-header {
    font-size: calc(10px + 2vmin);
    color: white;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .Home-text {
    color: white;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    font-style: italic;

  }

  .Home-header-logo {
    height: 10vmin;
    pointer-events: none;
  }

  .Home-link {
    cursor: pointer;
    position: relative;
    text-decoration: none !important;
    background-color: #27B589;
    margin: 20px 10px 0px 0px;
    border-radius: 25px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1)
  }

  .notification-badge {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

  .Home-link-container {
    padding: 0px 10px 0px 20px;
  }

  .Home-link-title {
    color: white;
    padding: 15px;
    text-align: center;
  }

  .Home-logo {
    align-self: flex-start;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
    min-height: 40vh;
  }
  
  .Home-logo-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .Home-whatsapp-link {
    color: #D9E8BB;
    background-color: #A4B28A;
    margin: 10px 0px 0px 0px;
    padding: 10px;
    border-radius: 13px;
    max-width: 290px;
  }

  .Home-circle-link {
    color: darkslategrey;
    background-color: #B0DECF;
    border-radius: 10px;
    align-self: flex-end;
    display: flex;
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 600;
    padding: 11px;
    margin-bottom: 10px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  }

  .Home-icon {
    margin-top: 10px;
    font-size: 20px;
    color: #6F7874;
  }

  .Home-drawer-link {
    color: #a9a9a9;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .Home-drawer-link-text {
    color: gray;
    margin: 10px;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 600;
  }
.Reminders {
    text-align: initial;
    background-image: linear-gradient(to bottom, #B0DECF , #F0FFFF);
    min-height: 100vh;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0px 10px 0px 20px;
    justify-content: space-between;
  }

  .Reminders-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .Reminders-header {
    font-size: calc(10px + 2vmin);
    color: white;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-start;
  }

  .Reminders-text {
    color: black;
    font-family: inherit;
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .Reminders-icon {
    margin: 10px;
    font-size: 20px;
    align-self: end;
    color: gray;
  }
  
  .Reminders-drawer-link {
    color: #a9a9a9;
    margin: 10px;
  }

  .Reminders-link {
    color: black;
    font-size: 20px;
  }

  .Reminders-item-link {
    color: black;
    font-size: 17px;
  }

  .Reminders-list {
    margin: 10px;
  }

  .Reminders-list-inline {
    list-style-type:circle;
    margin: 10px;
  }
.Reminder-medicine-link {
    border-radius: 8px;
}

.Reminder-medicine-header {
    margin: 15px 0px 10px 0px;
    border-radius: 8px;
    flex-direction: column;
    background-color: white;
    min-height: 600px;
    position: relative;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1)
}

.Medicine-reminder-text {
    color: #6F7874;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0px 0px 10px;
}

.Medicine-title-text {
  color: #6F7874;
  align-self: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0px 0px 10px;
}

.Medicine-reminders {
    text-align: initial;
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0px 10px 0px 10px;
    justify-content: flex-start;
  }

  .Medicine-reminder-tab-content {
    display: flex;
    flex: 1 1;
    background-color: 'white';
  }

  .Medicine-reminder-title-text {
    color: gray;
    margin: 10px;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 600;
  }

  .Medicine-container {
    display: flex;
    color: gray;
    flex: 1 1;
    flex-direction: column;
  }

  .Medicine-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .Medicine-dose-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: 20px;
  }

  .Medicine-dose-container {
    align-self: flex-start;
    margin-bottom: 10px;
  }

  .Medicine-dose-name {
    font-size: 14px;
    margin: 0;
    padding-left: 5px;
  }

  .Medicine-dose-name-checked {
    font-size: 14px;
    text-decoration: line-through;
    margin: 0;
    padding-left: 5px;
  }

  .Medicine-name {
    margin-right: 10px;
    color: white;
    margin-left: 20px;
  }

  .Medicine-detail {
    font-size: 13px;
    font-weight: 600;
  }

  .Medicine-alert-icon {
    margin-left: 5px;
    color: red;
  }

  .Medicine-detail-container {
    border-width: 1px;
    border-style: solid;
    border-color: lightgray;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .Medicine-details-icon {
    padding-right: 10px;
    color: gray;
  }

  .Medicine-details-ul {
    margin-top: 10px;
    color: gray;
  }

  .highlight {
    color: red !important;
 }

 .Medicine-details-icon-plus {
  padding-right: 10px;
  color: white;
 }

 .Medicine-add-detail-container {
    border-width: 0px;
    color: white;
    font-weight: 600;
    background-color: #10DB9C;
    border-radius: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
 }
  
 .modal-container {
  background-color: white;
  padding: 10px;
  text-align: center;
  align-self: center;
  border-color: #6F7874;
  margin-top: 100px;
  margin: 100px 20px 20px 20px;
 }

 .modal-container-success {
  background-color: green;
  padding: 10px;
  text-align: center;
  align-self: center;
  border-color: #6F7874;
  margin-top: 100px;
  margin: 100px 20px 20px 20px;
 }

 .modal-container-error {
  background-color: red;
  padding: 10px;
  text-align: center;
  align-self: center;
  border-color: #6F7874;
  margin-top: 100px;
  margin: 100px 20px 20px 20px;
 }

 .spinner-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51,51,51,0.7);
  z-index: 2500;
}
.loading-spinner {
  position: fixed;
  left: 45%;
  top: 45%;
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  -webkit-animation: spinner 1.5s linear infinite;
          animation: spinner 1.5s linear infinite;
}
.Reminder-medicine-link {
    border-radius: 8px;
    text-decoration: none !important;

}

.Reminder-medicine-header {
    margin: 15px 0px 10px 0px;
    border-radius: 8px;
    flex-direction: column;
    background-color: white;
    min-height: 600px;
    position: relative;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1)
}

.Medicine-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Medicine-reminder-text {
    color: #6F7874;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0px 0px 10px;
}

.Medicine-title-text {
  color: #6F7874;
  align-self: center;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0px 0px 10px;
}

.Medicine-reminders {
    text-align: initial;
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0px 10px 0px 10px;
    justify-content: flex-start;
  }

  .Medicine-reminder-tab-content {
    display: flex;
    flex: 1 1;
    background-color: 'white';
  }

  .Medicine-reminder-title-text {
    color: gray;
    margin: 10px;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 600;
  }

  .Medicine-container {
    display: flex;
    color: gray;
    flex: 1 1;
    flex-direction: column;
  }

  .Medicine-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .Medicine-dose-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: 20px;
  }

  .Medicine-dose-container {
    align-self: flex-start;
    margin-bottom: 10px;
  }

  .Medicine-dose-name {
    font-size: 14px;
    margin: 0;
    padding-left: 5px;
  }

  .Medicine-dose-name-checked {
    font-size: 14px;
    text-decoration: line-through;
    margin: 0;
    padding-left: 5px;
  }

  .Medicine-name {
    margin-right: 10px;
    color: 'gray';
    margin-left: 20px;
  }

  .Medicine-detail {
    font-size: 13px;
    font-weight: 600;
  }

  .Medicine-alert-icon {
    margin-left: 5px;
    color: red;
  }

  .Medicine-detail-container {
    border-width: 1px;
    border-style: solid;
    border-color: lightgray;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .Medicine-details-icon {
    padding-right: 10px;
    color: gray;
  }

  .Medicine-details-ul {
    margin-top: 10px;
    color: gray;
  }

  .highlight {
    color: red !important;
 }

 .Medicine-details-icon-plus {
  padding-right: 10px;
  color: white;
 }

 .Medicine-add-detail-container {
    border-width: 0px;
    color: white;
    font-weight: 600;
    background-color: #10DB9C;
    border-radius: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
 }
body,
html {
  height: 100%;
  margin: 0;
}

p {
  color: #c6dca7;
}

b {
  color: #FFFF;
}

.Wallet-header-container {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.Wallet-logo-header {
  margin-top: 1.25rem;
  height: 10vmin;
}

.Wallet-right-text {
  font-size: 2.5rem;
  margin-top: 17rem;
  margin-left: 8rem;

  .member-text {
    margin-top: -1.5rem;
    margin-bottom: 4rem;
    font-size: 1.5rem;
  }


  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
    margin-top: 10rem;
    margin-left: 5rem;

    .member-text {
      color: white;
      margin-top: -1.5rem;
      margin-bottom: -1.5rem;
      font-size: 1rem;
    }
  }
}

.Wallet-divider {
  display: flex;
  height: 80vh;

  @media only screen and (max-width: 600px) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    flex-direction: row;
    height: 50vh;
  }
}

.Wallet-left {
  flex: 0.6 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bccccc;

  .Image-wallet {
    height: 55vmin;
  }

  @media only screen and (max-width: 600px) {
    max-height: none;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    .Image-wallet {
      height: 55vmin;
    }
  }
}

.Image-wallet img {
  max-width: 80%;
  max-height: 80vh;
  height: 200vh;
  object-fit: contain;
}

.Wallet-right {
  flex: 1.1 1;
  background-color: #144943;
}

.Wallet-logo {
  margin: 3rem 0rem 0rem 50rem;
  height: 13vmin;

  @media only screen and (max-width: 600px) {
    margin: 0rem 0rem 10rem 21.5rem;
    height: 13vmin;
  }
}

.status-active-defaulter {
  color: black;
  background-color: yellow;
  border-radius: 1rem;
  padding: 5px 5px 5px 5px;
  opacity: 50%;
}

.upgrade-button {
  color: white;
  background-color: gray; 
  border-radius: 0.5rem;
  padding: 5px 5px 5px 5px;
  font-size: 1.5rem;

  @media only screen and (max-width: 600px) {
  font-size: 1rem;
  margin-bottom: 2rem;
  }
}

.wallet-unavailable {
  color: white;
  padding-top: 2rem;
  margin-bottom: 7rem;
}

.geolocation-button{
  background-color: #27B589 !important;
  border-radius: 25px !important;
  color: white !important;
}
.Reminder-medicine-link {
    border-radius: 8px;
}

.Reminder-medicine-header {
    margin: 15px 0px 10px 0px;
    border-radius: 8px;
    flex-direction: column;
    background-color: white;
    min-height: 600px;
    position: relative;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1)
}

.Medicine-reminder-text {
    color: #6F7874;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0px 0px 10px;
}

.Agenda-reminders {
    text-align: initial;
    background-image: linear-gradient(to bottom, #B0DECF , #F0FFFF);
    min-height: 100vh;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0px 10px 0px 10px;
    justify-content: flex-start;
  }

  .Medicine-reminder-tab-content {
    display: flex;
    flex: 1 1;
    background-color: 'white';
  }

  .Medicine-reminder-title-text {
    color: gray;
    margin: 10px;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 600;
  }

  .Medicine-container {
    display: flex;
    color: gray;
    align-items: center;
    flex: 1 1;
    justify-content: space-between;
  }

  .Medicine-name {
    margin-right: 10px;
    color: 'gray';
    margin-left: 20px;
  }

  .Medicine-detail {
    font-size: 13px;
    font-weight: 600;
  }

  .Medicine-detail-container {
    border-width: 1px;
    border-style: solid;
    border-color: lightgray;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .Medicine-details-icon {
    padding-right: 10px;
    color: gray;
  }

  .Agenda-details-ul {
    margin-top: 0;
    color: gray;
  }
.Health-cicle-link {
    border-radius: 8px;
    text-decoration: none !important;
}

.Health-cicle-header {
  margin: 15px 0px 10px 0px;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.Health-cicle-text {
    color: #6F7874;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0px 0px 10px;
}

.Health-cicle-header-logo {
  height: 10vmin; 
  pointer-events: none;
}

.Health-cicle-title-text {
  text-align: center;
  font-size: 14px;
}

.Health-cicle-title-logo {
  align-self: center;
  pointer-events: none;
}

.Health-cicle-chart-logo {
  align-self: center;
  pointer-events: none;
  max-width: 70%;
}

.Health-cicles {
    text-align: initial;
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0px 10px 0px 10px;
    justify-content: flex-start;
  }

  .Health-cicle-tab-content {
    display: flex;
    flex: 1 1;
    background-color: 'white';
  }

  .Health-cicle-title-text {
    color: gray;
    margin: 10px;
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 600;
  }

  .Health-cicle-container {
    display: flex;
    color: gray;
    align-items: center;
    flex: 1 1;
    justify-content: space-between;
  }

  .Health-cicle-name {
    color: #10DB9C;
    font-size: 16px;
    line-height: 50px;
    vertical-align: center !important;
  }

  .Health-cicle-name-gray {
    color: #A0A0A0;
    font-size: 16px;
    line-height: 50px;
    vertical-align: center !important;
  }

  .Health-cicle-name::marker {
    font-size: 50px;
  }

  .Health-cicle-name-gray::marker {
    font-size: 50px;
  }

  .Health-cicle-detail {
    font-size: 13px;
    font-weight: 600;
  }

  .Health-cicle-detail-container {
    margin-top: 25px;
    list-style-type:disc;
    width: 80%;
  }

  .Health-cicle-details-icon {
    padding-right: 10px;
    color: gray;
  }

  .Health-cicle-details-ul {
    margin-top: 0;
    color: gray;
  }
.Construction {
    text-align: center;
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .Construction-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .Construction-header {
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .Construction-text {
    color: #b3b3b3;
    font-family: inherit;
    font-size: 20px;
    margin: 20px;
  }

  .Construction-link {
    color: #a9a9a9;
    text-decoration: none !important;
    margin: 10px;
  } 

  .Construction-icon {
    margin: 10px;
    font-size: 20px;
    align-self: end;
    color: gray;
  }
.App {
    text-align: center;
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-header {
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #a9a9a9;
    text-decoration: none !important;
  } 
  
  .App-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
  }
  
  .App-form-input {
    background-color: #e8e8e8;
    margin: 10px 20px;
    border-width: 1px;
    font-size: 18px;
    border-radius: 10px;
    padding: 14px;
    border-style: solid;
  }
  
  .App-form-submit {
    font-size: 22px;
    background-color: #03a366;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    border-width: 0;
    padding: 14px;
    margin: 20px;
  }
  
  .App-error-container {
    background-color: red;
    width: 90%;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    position: absolute;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  

.Add_medicine {
    text-align: center;
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
  
  .Add_medicine-logo {
    height: 10vmin;
    pointer-events: none;
  }

  .Add_medicine-header {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .Add_medicine-header-container {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .Add_medicine-link {
    color: #a9a9a9;
    text-decoration: none !important;
  } 

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  } 

  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  .spinner-container {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(51,51,51,0.7);
    z-index: 10;
  }
  .loading-spinner {
    position: fixed;
    left: 45%;
    top: 45%;
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    -webkit-animation: spinner 1.5s linear infinite;
            animation: spinner 1.5s linear infinite;
  }
  
  .Add_medicine-form {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  
  .Add_medicine-form-input {
    background-color: #e8e8e8;
    border-width: 0px;
    width: 80%;
    font-size: 18px;
    border-radius: 10px;
    padding: 14px;
    border-style: solid;
  }
  
  .Add_medicine-form-submit {
    font-size: 22px;
    background-color: #03a366;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    border-width: 0;
    padding: 14px;
    margin: 20px;
  }
  
  .Add_medicine-form-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
  }
  .Add_medicine-error-container {
    background-color: red;
    width: 90%;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-weight: 700;
  }
  
  .Add_medicine-assert-container {
    background-color: green;
    width: 90%;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-weight: 700;
  }

  .Add_medicine-icon {
    margin-right: 10px;
  }
  
  @-webkit-keyframes Add_medicine-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes Add_medicine-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.medicine-check-confirmation{
  margin-right: 40px;
  margin-left: 40px;


  .check-confirmation-title{
    /* font-size: 40px; */
    padding-top: 150px;
    text-align: center;
  }

  h2{
    text-align: center;
  }

  .image-check-container{
    text-align: center;
    margin-bottom: -8rem;

    .image-check{
      max-width:250px;
      max-height:200px;
      width: auto;
      height: auto;
    }
  }
  
  .image-soul-logo{
    max-height:50px;
    max-width:100px;
    bottom: 0;
    margin-bottom: 20px;
    margin-left: 20%;
    position: fixed;
  }
}
.friend {
    background-color: green;
    background: green !important;
    color: white;
}


.friend-screen{
  padding-top: 10px;
}

h2{
 text-align: center;
}
.manage-friend {
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.heading {
  text-align: center;
  margin-bottom: 20px;
}

.card {
  margin-bottom: 20px;
}

.my-friends {
  background-color: #d4edda;
}

.requests-sent {
  background-color: #fff3cd;
}

.requests-received {
  background-color: #d1ecf1;
}

.button-confirm {
  background-color: #27B589 !important;
}

.add-new-friend {
  width: 100%;
  text-align: center;
  background-color: #27B589 !important;
  margin: 0px 10px 0px 0px;
  border-radius: 25px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1)
}

.add-send-new-friend{
  background-color: #27B589 !important;
}

.friend-screen {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.Home-icon {
  font-size: 24px;
  cursor: pointer;
  margin-right: 20px;
}

.Add_medicine-logo {
  height: 40px;
}

.list-item-title {
  font-size: 18px;
}

.small-button {
  padding: 4px 10px;
  font-size: 12px;
  background-color: rgb(84, 82, 82) !important;
}

.see-medicine-button {
  padding: 4px 10px;
  margin-left: 10px;
  background-color: #27B589 !important;
}

.disable-medicine-button {
  padding: 4px 10px;
  margin-left: 10px;
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none !important;
}
.calendar-container {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 10px 10px 10px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.navigation-buttons button {
  background-color: #27B589;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.navigation-buttons button:hover {
  background-color: #1b9770;
}
.days-container {
  display: flex;
  flex-direction: column;
}
.day {
  background-color: #F9F9F9;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.date {
  font-weight: bold;
  color: #333;
}
.dose-check {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  padding: 5px;
  margin-top: 5px;
  color: #555;
}

.dose-check-button{
  color: #27B589 !important;
}

.dose-not-check {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  padding: 5px;
  margin-top: 5px;
  color: #555;
}


.dose-not-check-button{
  color: #c70808 !important;
}

.dose-wait {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  padding: 5px;
  margin-top: 5px;
  color: #555;
}

.dose-wait-button{
  color: #007BFF !important;
}
