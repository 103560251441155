.Construction {
    text-align: center;
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .Construction-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .Construction-header {
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .Construction-text {
    color: #b3b3b3;
    font-family: inherit;
    font-size: 20px;
    margin: 20px;
  }

  .Construction-link {
    color: #a9a9a9;
    text-decoration: none !important;
    margin: 10px;
  } 

  .Construction-icon {
    margin: 10px;
    font-size: 20px;
    align-self: end;
    color: gray;
  }