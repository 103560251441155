.Add_medicine {
    text-align: center;
    background-color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
  
  .Add_medicine-logo {
    height: 10vmin;
    pointer-events: none;
  }

  .Add_medicine-header {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .Add_medicine-header-container {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .Add_medicine-link {
    color: #a9a9a9;
    text-decoration: none !important;
  } 

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .spinner-container {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(51,51,51,0.7);
    z-index: 10;
  }
  .loading-spinner {
    position: fixed;
    left: 45%;
    top: 45%;
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
  .Add_medicine-form {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  
  .Add_medicine-form-input {
    background-color: #e8e8e8;
    border-width: 0px;
    width: 80%;
    font-size: 18px;
    border-radius: 10px;
    padding: 14px;
    border-style: solid;
  }
  
  .Add_medicine-form-submit {
    font-size: 22px;
    background-color: #03a366;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    border-width: 0;
    padding: 14px;
    margin: 20px;
  }
  
  .Add_medicine-form-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
  }
  .Add_medicine-error-container {
    background-color: red;
    width: 90%;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-weight: 700;
  }
  
  .Add_medicine-assert-container {
    background-color: green;
    width: 90%;
    padding: 5px;
    border-radius: 5px;
    color: white;
    font-weight: 700;
  }

  .Add_medicine-icon {
    margin-right: 10px;
  }
  
  @keyframes Add_medicine-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  