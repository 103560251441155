.manage-friend {
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.heading {
  text-align: center;
  margin-bottom: 20px;
}

.card {
  margin-bottom: 20px;
}

.my-friends {
  background-color: #d4edda;
}

.requests-sent {
  background-color: #fff3cd;
}

.requests-received {
  background-color: #d1ecf1;
}

.button-confirm {
  background-color: #27B589 !important;
}

.add-new-friend {
  width: 100%;
  text-align: center;
  background-color: #27B589 !important;
  margin: 0px 10px 0px 0px;
  border-radius: 25px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1)
}

.add-send-new-friend{
  background-color: #27B589 !important;
}

.friend-screen {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.Home-icon {
  font-size: 24px;
  cursor: pointer;
  margin-right: 20px;
}

.Add_medicine-logo {
  height: 40px;
}

.list-item-title {
  font-size: 18px;
}

.small-button {
  padding: 4px 10px;
  font-size: 12px;
  background-color: rgb(84, 82, 82) !important;
}

.see-medicine-button {
  padding: 4px 10px;
  margin-left: 10px;
  background-color: #27B589 !important;
}

.disable-medicine-button {
  padding: 4px 10px;
  margin-left: 10px;
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none !important;
}